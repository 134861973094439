// css
import "../../scss/pages/404.scss";

// utils
import foundation from "../_utils/foundation";
foundation();

// libs
import ScrollObserver from "../_libs/ScrollObserver";
import HeaderSideNav from "../_libs/HeaderSideNav";

document.addEventListener("DOMContentLoaded", function () {
	HeaderSideNav();

	setTimeout(function () {
		document.querySelectorAll(".js-first-animation").forEach((element) => {
			element.classList.add("is-active");
		});
	}, 200);

	setTimeout(function () {
		document.querySelectorAll(".js-fv").forEach((element) => {
			element.classList.add("is-active");
		});
	}, 200);

	const scrollObserver_block = new ScrollObserver({
		target: ".js-block-target",
		addClass: "is-active",
		once: true,
		option: {
			rootMargin: "0px 0px -30% 0px",
		},
	});
	// 監視開始;
	scrollObserver_block.init();
});
window.addEventListener("load", () => {});
